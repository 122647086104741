/*****************************
* Layout - Offcanvas Menu
*****************************/
/*Mobile Menu Bottom Area*/

.offcanvas-menu {
    margin: 20px 0;
    font-size: 16px;
}

.offcanvas-menu li {
    position: relative;
    padding: 7px 5px;

    &.active{
        .offcanvas-menu-expand::after{
            color: $themeColor;
        }
        .offcanvas-menu-expand ~ a{
            color: $themeColor;
        }
    }
}

.offcanvas-menu>ul>li {
    border-bottom: 1px solid transparent;
}

.offcanvas-menu>ul>li:last-child {
    border-bottom: none;
}

.offcanvas-menu li a {
    display: block;
    color: $headingColor;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.offcanvas-menu li a:hover {
    color: $themeColor;
}

.offcanvas-menu li .mobile-sub-menu {
    display: none;
    cursor: pointer;
}

.offcanvas-menu-expand::after {
    content: "\eaa0";
    position: absolute;
    font-family: 'IcoFont';
    right: 0;
    transition: all 0.3s ease;
    color: $headingColor;
    font-size: 18px;
    line-height: 1.8;
    cursor: pointer;
}

.offcanvas-menu .active>.offcanvas-menu-expand:last-child,
.sub-menu>.active .offcanvas-menu-expand:last-child {
    border-bottom: none;
}

.offcanvas-menu .active>.offcanvas-menu-expand::after,
.sub-menu>.active .offcanvas-menu-expand::after {
    color: $headingColor;
    transform: rotate(90deg);
}


.mobile-contact-info{
  display: flex;
  justify-content: center;
  .social-link{
      display: flex;
      li{
        margin-right: 20px;
        &:last-child{margin-right: 0}
      }
      a{
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: $themeColor;
          border: 1px solid $themeColor;
          border-radius: 50%;
          display: inline-block;;
          transition: $baseTransition;

          @include breakpoint(xxlarge){
              width: 55px;
              height: 55px;
              line-height: 55px;
              margin-right: 25px;
          }
          &:hover{
              border-color: $themeColor;
              background: $themeColor;
              color: $white;
          }
      }
  }
}

/*Portfolio Details Page*/
.portfolio-details-wrapper{
    .default-text-content{
        margin-top: 62px;
        margin-bottom: -10px;
    }
}
.project-thumb-slider{
    margin-top: 40px;
    .projects-single-thumb-item {
        cursor: pointer;
    }
}
/*Service List Page*/

.service-items{
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    @include breakpoint(medium){
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(xlarge){
        grid-template-columns: repeat(3, 1fr);
    }
}
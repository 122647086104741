/***********************************
* Components - Forms
*************************************/
.default-form-single-item{
    input, textarea{
    background: #f9f9fa;
     border-radius: 10px;
     padding: 12px 25px;
     transition: $baseTransition;
     &::placeholder{
         color: #48515b;
     }
 }
}

.submit-btn{
    margin-top: 30px;
    @include breakpoint(medium){
        margin-top: 50px;
    }
    @include breakpoint(large){
        margin-top: 70px;
    }
}
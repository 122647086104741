.blog-details-top{
    .post-meata{
        font-size: 16px;
        margin-bottom: 35px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        color: #595d6b;
        
        span{
            position: relative;
            font-weight: 600;
            margin-right: 24px;
            &::after{
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                left: calc(100% + 13px);
                background: $themeColor;
                width: 4px;
                height: 4px;
                border-radius: 50%;
            }
            &:last-child{
                margin-right: 0;
                &::after{
                    display: none;
                }
            }

        }
        .author{
            font-weight: 700;
            a{
                color: #0f034a;
                padding-left: 10px;
            }
        }
    }

    .title{
        font-size: 28px;
        font-weight: 800;
        line-height: 1.38;
    
        @include breakpoint(small){
            font-size: 32px;
        }
        @include breakpoint(medium){
            font-size: 40px;
        }
        @include breakpoint(large){
            font-size: 42px;
        }
    }

    .blog-tag{
        margin-top: 45px;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        margin-bottom: 40px;
        @include breakpoint(large){
            margin-bottom: 50px;
            justify-content: center;
        }
        li{
            margin-right: 15px;
            margin-bottom: 15px;
            &:last-child{margin-right: 0;}

            @include breakpoint(large){
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }

        a{
            display: inline-block;
            padding: 5px 25px ;
            background: #f5f7fa;
            font-weight: 500;
            color: #383e38;
            position: relative;
            z-index: 1;

            &::after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $theme-linearGradient;
                z-index: -1;
                transition: $baseTransition;
                opacity: 0;
                visibility: hidden;
            }

            &:hover{
                color: $white;
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}


.blog-share{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    margin-top: 32px;
    padding: 15px 20px;
    @include breakpoint(large){
        padding: 15px 70px;
    }
    .text{
        margin-right: 30px;
        color: #0a1e43;
        font-size: 19px;
        font-weight: 700;
    }

    .social-link{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        @include breakpoint(medium){
            margin-top: 0;
        }

        li{
            margin-right: 15px;
        }

        a{
            height: 45px;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f4f4f4;
            color: #22262a;
            font-size: 14px;
            z-index: 1;
            position: relative;
            &::after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $theme-linearGradient;
                z-index: -1;
                transition: $baseTransition;
                opacity: 0;
                visibility: hidden;
            }

            &:hover{
                color: $white;
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.blog-feed-wrapper{
    margin-top: 38px;
}

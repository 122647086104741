.top-slider-buttons {
    display: flex;
    .slider-button{
        margin-top: 25px;
        margin-right: 20px;
        @include breakpoint(small){
            margin-top: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}

.default-slider{
    position: relative;
    z-index: 1;
    @include breakpoint(medium){
        margin: 0 80px;
    }
    @include breakpoint(large){
        margin: 0 70px;
    }
    @include breakpoint(xxlarge){
        margin: 0;
    }
    .default-slider-buttons{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        justify-content: space-between;
        width: 100%;
        display: none;
        @include breakpoint(medium){
            display: flex;
        }
    
        .button-prev,
        .button-next{
            opacity: 1;
            visibility: visible;
            transition: $baseTransition;
        }

        @include breakpoint(medium){
            .button-prev{ transform: translateX(-79px);}
            .button-next{transform: translateX(79px);}
        }
        @include breakpoint(xlarge){
            .button-prev{ transform: translateX(-65px);}
            .button-next{transform: translateX(65px);}
        }
        @include breakpoint(xxlarge){
            .button-prev{ transform: translateX(-79px);}
            .button-next{transform: translateX(79px);}
        }
        
    }
}



.slider-button{
    width: 52px;
    height: 52px;
    border-radius: 10px;
    border: 1px solid rgba(#0f034a, .1);
    text-align: center;
    line-height: 52px;
    color: #0f034a;
    background: $white;
    font-size: 25px;
    position: relative;
    z-index: 1;

    &::after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: $theme-linearGradient;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
        transition: $baseTransition;
        opacity: 0;
        visibility: hidden;
    }

    &:hover{
        color: $white;
        &::after{
            opacity: 1;
            visibility: visible;
        }
    }
}


/*****************************
* Base - Default
*****************************/
/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/***********************************
Fonts Family:
------------
1. font-family: 'Manrope', sans-serif;
-> Extra-light 200, Light 300, Regular 400, Medium 500, Semi-bold 600, Bold 700, Extra-bold 800

2.  font-family: 'Roboto', sans-serif;
-> Thin 100, Light 300, Regular 400, Medium 500, Bold 700, Black 900

**************************************/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

body {
  font-size: 16px;
  font-family: $body-font;
  font-weight: 400;
  font-style: normal;
  line-height: 1.875;
  position: relative;
  visibility: visible;
  background: $white;
  color: $bodyColor;

  &[dir="rtl"] {
    text-align: right;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  color: $headingColor;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: $themeColor;
  text-decoration: none;
  transition: $baseTransition;
  outline: none;
}

p {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

a,
button,
input,
textarea {
  border: none;
  outline: none !important;
  background: none;
  box-shadow: none;
}

input, textarea {
  width: 100%;
}

.ui-datepicker {
  z-index: 11;
}


/*Bootstrap Container Customize*/
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

/*Custome Container Customize*/
.custome-container-with-sidebar{
  .container-width-35{
    width: 100%;
    margin-top: 50px;
    @include breakpoint(medium){
      margin-top: 70px;
    }
    @include breakpoint(large){
      margin-top: 0px;
      width: calc(45% - 60px);
    }
    @include breakpoint(xlarge){
      margin-top: 0px;
      width: calc(35% - 60px);
    }
 
    &.sidebar-rightside{
      margin-left: 0;
      @include breakpoint(large){
        margin-left: 0px;
      }
    }
    &.sidebar-leftside{
      margin-right: 0;
      @include breakpoint(large){
        margin-right: 0px;
      }
    }
  }
  .container-width-65{
    width: 100%;

    @include breakpoint(large){
      width: 55%;
    }
    @include breakpoint(xlarge){
      width: 65%;
    }
  }

  .container-width-40{
    width: 100%;
    margin-top: 50px;
    @include breakpoint(medium){
      margin-top: 70px;
    }
    @include breakpoint(large){
      margin-top: 0px;
      width: calc(45% - 70px);
    }
    @include breakpoint(xlarge){
      margin-top: 0px;
      width: calc(40% - 70px);
    }

    &.sidebar-rightside{
      margin-left: 0;
      @include breakpoint(large){
        margin-left: 70px;
      }
    }
    &.sidebar-leftside{
      margin-right: 0;
      @include breakpoint(large){
        margin-right: 70px;
      }
    }
  }
  .container-width-60{
    width: 100%;

    @include breakpoint(large){
      width: 55%;
    }
    @include breakpoint(xlarge){
      width: 65%;
    }
  }

  .container-width-37{
    width: 100%;
    margin-top: 50px;
    @include breakpoint(medium){
      margin-top: 70px;
    }
    @include breakpoint(large){
      margin-top: 0px;
      width: calc(45% - 70px);
    }
    @include breakpoint(xlarge){
      margin-top: 0px;
      width: calc(37% - 70px);
    }

    &.sidebar-rightside{
      margin-left: 0;
      @include breakpoint(large){
        margin-left: 70px;
      }
    }
    &.sidebar-leftside{
      margin-right: 0;
      @include breakpoint(large){
        margin-right: 70px;
      }
    }
  }
  .container-width-63{
    width: 100%;

    @include breakpoint(large){
      width: 55%;
    }
    @include breakpoint(xlarge){
      width: 63%;
    }
  }
}

/*Page Navigation*/
.page-navigation{
  display: flex;
  justify-content: space-between;

  li{
    &:nth-child(1){
      a{
        flex-direction: row;
        .text{
          padding-right: 20px;
          @include breakpoint(medium){
            padding-right: 35px;
          }
        }
      }
    }
    &:nth-child(2){
      a{
        flex-direction: row-reverse;
        .text{
          padding-left: 20px;
          @include breakpoint(medium){
            padding-left: 35px;
          }
        }
      }
    }
  }

  a{
    display: flex;
  }

  .text{
    font-family: $heading-font;
    line-height: 1;
    color: #10253b;
    transition: $baseTransition;
    font-size: 30px;

    @include breakpoint(medium){
      font-size: 35px;
    }
  }

  .icon{
    position: relative;
    img{
      transition: $baseTransition;
      
      &:nth-child(1){
        opacity: 1;
      }
      &:nth-child(2){
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-8px);
        opacity: 0;
      }
    }


  }

  a:hover{

    .text{
      color: $colorOrange;
    }

    .icon{
      img{
        &:nth-child(1){
          opacity: 0;
        }
        &:nth-child(2){
          opacity: 1;
        }
      }
    }
  }
}

.border-bottom{
 border-bottom: 1px solid #f4f4f4
}

@import "common/animation";
@import "common/typography";
@import "common/button";
@import "common/form";
@import "common/section";
@import "common/utilities";
@import "common/slider";
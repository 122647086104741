/*****************************
* Components - Service Display
*****************************/
.service-dispaly-bg {
    background-color: rgba(230, 232, 234, 1);
    background-image: url("../images/background/service-display-bg.png");
    background-position: top center;
    background-size: cover;
}

.service-single-item {

    padding: 65px 40px 40px 55px;
    transition: $baseTransition;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: $baseTransition;
        background: linear-gradient(135deg, rgba(73, 163, 254, 1) 0%, rgba(0, 55, 172, 1) 100%);
    }
    &::after {
        position: absolute;
        content: "";
        width: 55px;
        height: 240px;
        background: linear-gradient(350deg, rgba(18, 81, 192, 1) 0%, rgba(28, 97, 204, 0) 100%);
        transform: skewY(10deg);
        bottom: 5px;
        right: -55px;
        z-index: -1;
        transition: all .5s ease;
        opacity: 0;
    }

    .icon {
        position: relative;
        width: 60px;
        height: 60px;
        z-index: 1;

        img {
            object-fit: cover;
            transition: $baseTransition;

            &:nth-child(2) {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }

        &::after {
            position: absolute;
            content: "";
            width: 90px;
            height: 90px;
            top: -35px;
            left: -25px;
            border-radius: 50%;
            z-index: -1;
        }
    }

    .content {
        .title {
            font-size: 20px;
            font-weight: 800;
            line-height: 1.52;
            margin-top: 47px;
            margin-bottom: 25px;
            @include breakpoint(xlarge){font-size: 25px;}
            a {
                color: $headingColor;
                transition: $baseTransition;
            }
        }

        p {
            font-size: 17px;
            font-weight: 500;
            line-height: 1.76;
        }

        .text-btn {
            font-size: 17px;
            font-weight: 600;
            color: $themeColor;
            padding-left: 55px;
            position: relative;

            .arrow-icon {
                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: $baseTransition;

                    &:nth-child(1) {
                        left: 0%;
                        visibility: visible;
                        opacity: 1;
                        transition: all .2.5s ease;
                    }

                    &:nth-child(2) {
                        left: calc(100% + 0px);
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }



    &:hover {
        
        &::before{
            opacity: 1;
        }
        &::after {
            right: 0;
            opacity: 1;
            

        }

        .icon {
            img {
                transition: $baseTransition;

                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }

        .title a {
            color: $white;
        }

        p {
            color: $white;
        }

        .text-btn {
            padding-left: 0px;
            color: $white;

            .arrow-icon {
                img {
                    &:nth-child(1) {
                        visibility: hidden;
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        left: calc(100% + 25px);
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-style-1{
        background: $white;
        .icon {
            &::after{
                background: rgb(234, 236, 237);
                background: linear-gradient(135deg, rgba(234, 236, 237, .4) 0%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }
    &-style-2{
        background: #f9f9fa;
        .icon {
            &::after{
                background: rgb(234, 236, 237);
                background: linear-gradient(180deg, rgba(225, 228, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }

        &:hover{
            .icon {
                &::after{
                    background: linear-gradient(180deg, rgba(225, 228, 231, .4) 0%, rgba(255, 255, 255, 0) 100%);
                }
            }
        }
    }
}
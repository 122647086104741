/*****************************
* Layout - Mobile Header
*****************************/
.mobile-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: $white;
    padding: 20px 0;
}

.mobile-action-link{
    font-size: 24px;

    a{color: $themeColor;}
}

